import { useCallback, useState } from "react";

import registerSchema from "../../utils/registerSchema";

const useRegister = () => {
  const [showOtherPharmacy, setShowOtherPharmacy] = useState(false);

  const schema = registerSchema([
    {
      name: "name",
      type: "String",
    },
    {
      name: "fatherLastName",
      type: "String",
    },
    {
      name: "motherLastName",
      type: "String",
    },
    {
      name: "professionalID",
      type: "String",
    },
    {
      name: "phone",
      type: "Number",
    },
    {
      name: "email",
      type: "Email",
    },
    {
      name: "confirmEmail",
      type: "Email",
    },
    {
      name: "password",
      type: "Password",
    },
    {
      name: "confirmPassword",
      type: "Password",
    },
    {
      name: "privacy",
      type: "Boolean",
    },
    {
      name: "specialty",
      type: "Select",
      options: ["Selecciona una especialidad"],
    },
    {
      name: "country",
      type: "Select",
      options: ["Selecciona un país"],
    },
    {
      name: "placeWork",
      type: "Select",
      options: ["Selecciona un lugar de trabajo"],
    },

    ...(showOtherPharmacy
      ? [
          {
            name: "otherPharmacy",
            type: "String",
            options: ["El Nombre de la Farmacia es requerido"],
          },
        ]
      : []),
  ]);

  const handleDrugstore = useCallback((e: string) => {
    setShowOtherPharmacy(e === "otros");
  }, []);

  return {
    setShowOtherPharmacy,
    showOtherPharmacy,
    handleDrugstore,
    schema,
  };
};

export default useRegister;
