export const pharmacies = [
  {
    id: "otros",
    name: "Otros",
  },

  {
    id: "soriana",
    name: "Soriana",
  },

  {
    id: "farmacias-roma",
    name: "Farmacias Roma",
  },
];
